import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container as ContainerBase, ContentWithPaddingXl as ContentBase } from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "images/checkbox-circle.svg";
import { ReactComponent as StripeIcon} from "images/stripe-clear.svg";

const Container = tw(ContainerBase)`bg-black text-gray-100 -mx-8 px-8`;
const ContentWithPaddingXl = tw(
  ContentBase
)`relative z-10 mx-auto px-0 py-10 sm:px-6 md:px-8 lg:px-12 xl:px-24 sm:py-20 flex flex-col max-w-screen-xl`;
const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-gray-300 text-center`;

const PlansContainer = tw.div`mt-16 flex flex-col items-center lg:flex-row lg:items-stretch lg:justify-between text-gray-100 font-medium`;
const Plan = styled.div`
  ${tw`w-full max-w-sm bg-gray-900 rounded-lg shadow-sm py-10 px-6 sm:px-10 lg:px-6 lg:py-10 xl:p-10 mx-3 flex flex-col justify-between mt-16 first:mt-0 lg:mt-0 shadow-raised`}
`;

const PlanHeader = styled.div`
  .nameAndFeaturedContainer {
    ${tw`flex flex-wrap flex-col sm:flex-row justify-between items-center`}
  }
  .name {
    ${tw`lg:text-lg xl:text-xl font-bold uppercase tracking-wider mr-3`}
  }
  .featuredText {
    ${tw`text-xs font-bold px-3 rounded py-2 uppercase bg-green-300 text-green-900 leading-none mt-4 sm:mt-0 w-full sm:w-auto text-center`}
  }
  .pricingContainer {
    ${tw`mt-6 flex items-end justify-between`}
    .currentPrice {
      ${tw`text-lg font-bold leading-none`}
      .bigText {
        ${tw`text-3xl font-bold`}
      }
    }
    .oldPrice {
      ${tw`text-gray-500 text-lg line-through hidden sm:block`}
    }
  }
  .description {
    ${tw`mt-8 font-medium text-gray-300 lg:text-sm xl:text-base`}
  }
`;
const PlanFeatures = styled.ul`
  ${tw`mt-10 flex-1 lg:-mx-6 -mx-6 sm:-mx-10 py-10 px-6 sm:px-10 lg:p-6 xl:-mx-10 xl:p-10`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0`}
    .icon {
      ${tw`w-6 h-6 text-blue-500 flex-shrink-0`}
    }
    .text {
      ${tw`font-semibold text-gray-100 tracking-wide ml-3`}
    }
  }
`;

const PlanAction = tw.div`mt-4`;
const ActionButton = styled(PrimaryButtonBase)`
  ${tw`block text-center text-sm font-semibold tracking-wider w-full text-gray-100 bg-blue-500 px-6 py-4 rounded hover:bg-blue-700 focus:shadow-outline focus:outline-none transition-colors duration-300`}
`;

const WhiteBackgroundOverlay = tw.div`absolute inset-x-0 bottom-0 h-1/6 lg:h-1/3 bg-white z-0`;

const SubmitButton = styled.button`
  ${tw`mt-6 text-center tracking-wider font-semibold bg-blue-500 text-gray-100 w-full py-4 rounded-lg transition-all duration-300 items-center ease-in-out flex focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;

export default ({
  subheading = "",
  heading = "Subscription Plans",
  description = "",
  plans = null,
  primaryButtonText = "Select"
}) => {
  const defaultPlans = [
    {
      name: "Risk Free 3 Day Trial",
      price: ["$0", "/month"],
      oldPrice: "",
      description: "Experience all we have to offer during a free 3 day trial. If you think it's a good match, we'd love for you to join the E1 fam.",
      features: ["Real-time trade alerts", "Daily trade streams", "Daily premarket plan", "Discuss ideas with other E1 family members", "Access to our Discord bots for charting and option flow requests"],
      url: "https://buy.stripe.com/5kA9AN9xS7wxbKgaEH"
    },
    {
      name: "Monthly Access",
      price: ["$99", "/month"],
      oldPrice: "",
      description: "Join the E1 family! This plan is month-to-month and can be canceled at any time.",
      features: [
        "Real-time trade alerts",
        "Daily trade streams",
        "Daily premarket plan",
        "Discuss ideas with other E1 family members",
        "Access to Discord bots for charting and option flow requests",
        "Access to all proprietary E1 indicators",
        "Access to E1 supply/demand scanner",
        "Weekly watchlists of high risk/reward setups",
        "Educational materials"
      ],
      url: "https://buy.stripe.com/5kA9AN9xS7wxbKgaEH",
      featured: "Most Popular"
    },
    {
      name: "Yearly Access",
      price: ["$1069", "/year"],
      oldPrice: "$1188",
      description: "If you know that E1 is for you, stick with us for a year at a discounted price. This plan is billed once a year.",
      features: [
        "Real-time trade alerts",
        "Daily trade streams",
        "Daily premarket plan",
        "Discuss ideas with other E1 family members",
        "Access to Discord bots for charting and option flow requests",
        "Access to all proprietary E1 indicators",
        "Access to E1 supply/demand scanner",
        "Weekly watchlists of high risk/reward setups",
        "Educational materials"
      ],
      url: "https://buy.stripe.com/14k8wJcK4aIJaGc145"
    }
  ];

  if (!plans) plans = defaultPlans;

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              <PlanHeader>
                <span className="nameAndFeaturedContainer">
                  <span className="name">{plan.name}</span>
                  {plan.featured && <span className="featuredText">{plan.featured}</span>}
                </span>
                <div className="pricingContainer">
                  <span className="currentPrice">
                    <span className="bigText">{plan.price[0]}</span>
                    {plan.price[1]}{" "}
                  </span>
                  {plan.oldPrice && <span className="oldPrice">{plan.oldPrice}</span>}
                </div>
                <p className="description">{plan.description}</p>
              </PlanHeader>
              <PlanFeatures>
                {plan.features.map((feature, index) => (
                  <li className="feature" key={index}>
                    <CheckboxIcon className="icon" />
                    <span className="text">{feature}</span>
                  </li>
                ))}
              </PlanFeatures>
              <PlanAction>
                {/* <ActionButton as="a" href={plan.url}>
                  <a>
                  <StripeIcon width="100" height="25" vertical-align="middle"/> 
                  {primaryButtonText}
                  </a>
                </ActionButton> */}
                <SubmitButton as="a" href={plan.url}>
                  <StripeIcon height="25" width="100"/>
                  <span className="text">{primaryButtonText}</span>
                </SubmitButton>
              </PlanAction>
            </Plan>
          ))}
        </PlansContainer>
      </ContentWithPaddingXl>
      {/* <WhiteBackgroundOverlay /> */}
    </Container>
  );
};
