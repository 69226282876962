import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import HeaderBase, {
  LogoLink as LogoLinkBase,
  NavLinks,
  NavLink as NavLinkBase,
  PrimaryLink as PrimaryLinkBase
} from "../headers/light.js";
import { Container as ContainerBase, ContentWithVerticalPadding, Content2Xl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import logoImageSrc from "images/e1_logo.png";
import heroImage from "images/e1_website_logo.png";
import {Link} from 'react-scroll'

const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 bg-black text-gray-100`;
const Header = tw(HeaderBase)`max-w-none -mt-8 py-8 -mx-8 px-8`;
const NavLink = tw(NavLinkBase)`lg:text-gray-100 lg:hocus:text-gray-300 lg:hocus:border-gray-100`;
const LogoLink = tw(LogoLinkBase)`text-gray-100 hocus:text-gray-300`;
const PrimaryLink = tw(PrimaryLinkBase)`shadow-raised lg:bg-blue-500 lg:hocus:bg-blue-500`;

const Container = tw(ContainerBase)``;
const Row = tw.div`flex items-center flex-col lg:flex-row`;
const Column = tw.div`lg:w-1/2`;
const TextColumn = tw.div`text-center lg:text-left`;
const IllustrationColumn = tw(Column)`mt-16 lg:mt-0 lg:ml-16`;
const Heading = tw(SectionHeading)`max-w-3xl lg:max-w-4xl lg:text-left leading-tight`;
const Period = tw.span`text-blue-500`
const Description = tw(SectionDescription)`mt-4 max-w-2xl text-gray-100 lg:text-base mx-auto lg:mx-0`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 text-sm sm:text-base px-6 py-5 sm:px-10 sm:py-5 bg-blue-500 inline-block hocus:bg-blue-500`;
const Image = tw.img`w-144 ml-auto`

export default ({
  heading = "Take the Guesswork Out of Trading",
  description = "Let's face it— trading is hard. Echelon 1 is a community built for traders that want to grow alongside others. We help you grow as a trader through education and mentorship while also sharing day trade and swing trade ideas.",
  primaryButtonText = "Start Your 3 Day Free Trial",
  primaryButtonUrl = "#",
  imageSrc = heroImage,
}) => {
  const logoLink = (
    <LogoLink href="/">
      <img src={logoImageSrc} alt="Logo" />
      Echelon 1
    </LogoLink>
  );
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href='#'>
        <Link to="features" spy={true} smooth={true}>Features</Link>
      </NavLink>
      <NavLink href="#"><Link to="pricing" spy={true} smooth={true}>Pricing</Link></NavLink>
      <NavLink href="#"><Link to="testimonials" spy={true} smooth={true}>Testimonials</Link></NavLink>
      {/* <NavLink href="#"><Link to="trade-log" spy={true} smooth={true}>Trade Log</Link></NavLink> */}
      <NavLink href="#"><Link to="faq" spy={true} smooth={true}>FAQ / Disclaimer</Link></NavLink>
      {/* <NavLink href="#"><Link to="disclaimer" spy={true} smooth={true}>Disclaimer</Link></NavLink> */}
      <NavLink href="#"><Link to="contact" spy={true} smooth={true}>Contact Us</Link></NavLink>
      <NavLink href="https://mysuperportal.com/access/echelon-1/">Manage Account</NavLink>
      <PrimaryLink href="#" ><Link to="pricing" spy={true} smooth={true}>Join Us</Link></PrimaryLink>
    </NavLinks>
  ];
  return (
    <PrimaryBackgroundContainer>
      <Content2Xl>
        <Header logoLink={logoLink} links={navLinks} />
        <Container>
          <ContentWithVerticalPadding>
            <Row>
              <TextColumn>
                <Heading>{heading}<Period>.</Period></Heading>
                <Description>{description}</Description>
                <PrimaryButton as="a" href={primaryButtonUrl}><Link to="pricing" spy={true} smooth={true}>{primaryButtonText}</Link></PrimaryButton>
              </TextColumn>
              <IllustrationColumn>
                <Image src={imageSrc} />
              </IllustrationColumn>
            </Row>
          </ContentWithVerticalPadding>
        </Container>
      </Content2Xl>
    </PrimaryBackgroundContainer>
  );
};
