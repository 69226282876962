import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import LiveTradeIcon from "images/pulse.svg";
import PremarketPlanIcon from "images/chart-line-variant.svg";
import StreamIcon from "images/monitor.svg";
import EducationIcon from "images/book-open-page-variant.svg";
import AlgoIcon from "images/atom.svg";
import NewsletterIcon from "images/calendar-check.svg";


const Container = tw.div`relative bg-black -mx-8 px-8 text-gray-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-300`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-400 leading-snug`}
  }
`;

export default ({
  cards = null,
  heading = "Features",
  subheading = "",
  description = ""
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  // live trade alerts - done
  // weekly newsletter - done
  // daily premarket plan - done
  // live trade streams - done
  // family community
  // educational content - done
  // algo - done
  // market navigation

  const defaultCards = [
    {
      imageSrc: LiveTradeIcon,
      title: "Real-time trade alerts",
      description: "See every trade we take in real time. All alerts include entries, stop losses, and charts."
    },
    {
      imageSrc: StreamIcon,
      title: "Daily Trade Streams",
      description: "Trade alongside us as we stream charts and trade ideas in real time. Got questions during the market? Ask us!"
    },
    {
      imageSrc: PremarketPlanIcon,
      title: "Daily Premarket Plan",
      description: "Every morning before market open, we share a market outlook and key market levels to watch powered by option order flow and gamma levels."
    },
    {
      imageSrc: AlgoIcon,
      title: "Proprietary Indicators",
      description: "We've developed in-house indicators that help identify and make better trading decisions. These aren't your run-of-the-mill SuperTrend or EMA cloud copies. Algo-based trading coming soon!"
    },
    {
      imageSrc: EducationIcon,
      title: "Educational Materials",
      description: "We provide educational materials to help you grow as a trader. Want to learn how to identify high risk/reward setups? Need help managing risk? We got you covered."
    },
    {
      imageSrc: NewsletterIcon,
      title: "Weekly Watchlist",
      description: "Every week, our analysts will recap market conditions and share expectations for the week. Additionally, we share a list of high risk/reward plays backed by simple and powerful charts and option order flow."
    },
    // { imageSrc: SupportIconImage, title: "24/7 Support" },
    // { imageSrc: CustomizeIconImage, title: "Customizable" },
    // { imageSrc: ReliableIconImage, title: "Reliable" },
    // { imageSrc: FastIconImage, title: "Fast" },
    // { imageSrc: SimpleIconImage, title: "Easy" }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" style={{ height: 28, width: 28 }}/>
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
