import React, { useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "../misc/Headings.js";
import { PrimaryButton } from "../misc/Buttons.js";
import { ReactComponent as QuotesLeftIcon } from "../../images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../../images/quotes-r.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-4.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-5.svg";

import "slick-carousel/slick/slick.css";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const TestimonialsContainer = tw.div`mt-16 lg:mt-0`;
const Testimonials = styled.div``;
const Testimonial = tw.div`max-w-md lg:max-w-none mx-auto lg:mx-0 flex flex-col items-center lg:items-stretch lg:flex-row`;

const TestimonialImageSlider = tw(Slider)`w-full lg:w-5/12 flex-shrink-0 `;
const TestimonialTextSlider = tw(Slider)``;
const TestimonialText = tw.div`outline-none`;

const ImageAndControlContainer = tw.div`relative outline-none`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-80 sm:h-96 lg:h-144`
]);

const ControlContainer = tw.div`absolute bottom-0 right-0 bg-gray-900 px-6 py-4 rounded-tl-3xl`;
const ControlButton = styled(PrimaryButton)`
  ${tw`mx-3 rounded-full bg-blue-500 hocus:bg-blue-400 text-gray-100 p-2`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const TextContainer = styled.div(props => [
  tw`flex flex-col w-full lg:w-7/12`,
  props.textOnLeft ? tw`lg:pr-12 lg:order-first` : tw`lg:pl-12 lg:order-last`
]);

const Subheading = tw(SubheadingBase)`mb-4`;
const HeadingTitle = tw(SectionHeading)`lg:text-left text-gray-100 leading-tight`;
const Description = tw.p`max-w-md text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-gray-500`;

const QuoteContainer = tw.div`relative mt-10 lg:mt-20`;
const Quote = tw.blockquote`text-center lg:text-left text-sm sm:text-lg lg:text-xl xl:text-2xl text-gray-100`;
const CustomerInfo = tw.div`mt-6 flex flex-col sm:flex-row items-center justify-center lg:justify-start`;
const CustomerProfilePicture = tw.img`rounded-full w-20 h-20`;
const CustomerTextInfo = tw.div`text-center lg:text-left sm:ml-6 mt-2 sm:mt-0`;
const CustomerName = tw.h5`font-semibold text-xl lg:text-2xl xl:text-3xl text-blue-500`;
const CustomerTitle = tw.p`font-medium text-gray-100`;

const QuotesLeft = tw(QuotesLeftIcon)`w-6 h-6 opacity-75 text-blue-400 inline-block mr-1 -mt-3`;
const QuotesRight = tw(QuotesRightIcon)`w-6 h-6 opacity-75 text-blue-400 inline-block ml-1 -mt-3`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute w-32 top-0 left-0 -z-10 text-primary-500 opacity-25 transform -translate-x-full`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`;

export default ({
  subheading = "",
  heading = "Member-Only Tools",
  description = "All members and trialists have access to proprietary tools built for traders, by traders. All available through Discord and TradingView, examples of tools include darkpool watchlists, real-time option flow heatmaps, supply & demand indicators, daily trading ranges, and so much more.",
  testimonials = null,
  textOnLeft = false
}) => {
  /*
   * You can modify the testimonials shown by modifying the array below or passing in the testimonials prop above
   * You can add or remove objects from the array as you need.
   */
  const defaultTestimonials = [
    {
      imageSrc:
        "https://imgur.com/sEUfoqa.png",
      quote: "Darkpools are massive trades executed by market makers outside of regular exchanges. These darkpools act as magnets for prices and can help determine a bullish or bearish bias. Don't get caught on the wrong side of a trade! Darkpools are provided daily during pre-market by our darkpool and option flow analyst.",
      customerName: "Daily Darkpools",
      customerTitle: 'Provided daily in pre-market, way before any other tool like FlowAlgo and CheddarFlow pick it up.'
    },
    {
      imageSrc:
        "https://pbs.twimg.com/media/FJ5eiIGXsAIRvR7?format=jpg&name=900x900",
      quote: "Option order flow almost always precedes price action. Why? Market makers have more information available to them and so they position themselves accordingly- option order flow deciphers how market makers are positioned. With one glance of our proprietary flow heatmap, you can develop a bullish/bearish bias and identify key levels that MMs expect to hit.",
      customerName: "Real-time Option Flow Heatmap",
      customerTitle: "Accessible via Discord bot command, see how market makers continuously position themselves to be profitable."
    },
    {
      imageSrc:
        "https://imgur.com/QZPRhd9.png",
      quote: "Our proprietary trading range algorithm accurately identifies the expected range for the day to help you capitalize on both up and down movements. In the event of breakouts/breakdowns, the algorithm identifies key price targets to help you identify better exits. The crazy part? It does all of this before the market even opens.",
      customerName: "Daily Predicted Trading Range",
      customerTitle: "Accessible as a TradingView indicator, this powerful price action-based algorithm predicts the trading range and key price targets for the day before the market even opens. "
    },
    {
      imageSrc:
        "https://imgur.com/oUhvldq.png",
      quote: "As markets move up and down, they leave behind an invisible trail of important price action. This dynamic indicator intelligently deciphers previous price action to identify the current trend as well as supply and demand zones. With this, you can identify high probability reversals, squeeze more profit out of plays, and know when to cut a trade.",
      customerName: "Dynamic Supply and Demand",
      customerTitle: "Accessible as a TradingView indicator, this indicator dynamically identifies supply and demand zones as the market moves up and down."
    },
    {
      imageSrc:
        "https://imgur.com/ULKGrAG.png",
      quote: "Markets move up when people want to buy and move down when sellers want to sell. If you can identify when buyers want to buy and when sellers want to sell, you can determine what price wants to do. This indicator identifies when buyers/sellers are stepping in or are exhausted. WIth this, you can spot high potential reversals and buy the dip or sell the rip.",
      customerName: "Absorption and Exhaustion Detector",
      customerTitle: "Accessible as a TradingView indicator, this indicator identifies when buyers/sellers are stepping in to hold price and when buyers/sellers are exhausted."
    },
  ];

  if (!testimonials || testimonials.length === 0) testimonials = defaultTestimonials;

  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [imageSliderRef, setImageSliderRef] = useState(null);
  const [textSliderRef, setTextSliderRef] = useState(null);

  return (
    <Container>
      <Content>
        <HeadingInfo tw="text-center text-gray-100 lg:hidden" subheading={subheading} heading={heading} description={description} />
        <TestimonialsContainer>
          <Testimonials>
            <Testimonial>
              <TestimonialImageSlider arrows={false} ref={setImageSliderRef} asNavFor={textSliderRef} fade={true}>
                {testimonials.map((testimonial, index) => (
                  <ImageAndControlContainer key={index}>
                    <Image imageSrc={testimonial.imageSrc} />
                    <ControlContainer>
                      <ControlButton onClick={imageSliderRef?.slickPrev}>
                        <ChevronLeftIcon />
                      </ControlButton>
                      <ControlButton onClick={imageSliderRef?.slickNext}>
                        <ChevronRightIcon />
                      </ControlButton>
                    </ControlContainer>
                  </ImageAndControlContainer>
                ))}
              </TestimonialImageSlider>
              <TextContainer textOnLeft={textOnLeft}>
                <HeadingInfo tw="hidden lg:block" subheading={subheading} heading={heading} description={description} />
                <TestimonialTextSlider arrows={false} ref={setTextSliderRef} asNavFor={imageSliderRef} fade={true}>
                  {testimonials.map((testimonial, index) => (
                    <TestimonialText key={index}>
                      <QuoteContainer>
                        <Quote>
                          <QuotesLeft />
                          {testimonial.quote}
                          <QuotesRight />
                        </Quote>
                      </QuoteContainer>
                      <CustomerInfo>
                        <CustomerTextInfo>
                          <CustomerName>{testimonial.customerName}</CustomerName>
                          <CustomerTitle>{testimonial.customerTitle}</CustomerTitle>
                        </CustomerTextInfo>
                      </CustomerInfo>
                    </TestimonialText>
                  ))}
                </TestimonialTextSlider>
              </TextContainer>
            </Testimonial>
          </Testimonials>
        </TestimonialsContainer>
      </Content>
      {/* <DecoratorBlob1 />
      <DecoratorBlob2 /> */}
    </Container>
  );
};

const HeadingInfo = ({ subheading, heading, description, ...props }) => (
  <div {...props}>
    {subheading ? <Subheading>{subheading}</Subheading> : null}
    <HeadingTitle>{heading}</HeadingTitle>
    <Description>{description}</Description>
  </div>
);
