import React, {useState} from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as QuoteIconBase } from "images/quotes-l.svg"
import { ReactComponent as ArrowLeftIcon } from "images/arrow-left-3-icon.svg"
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg"
import { ReactComponent as QuotesLeftIcon } from "../../images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../../images/quotes-r.svg";

import "slick-carousel/slick/slick.css";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-black text-gray-100`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-gray-300`;

const TestimonialsSlider = styled(Slider)`
  ${tw`flex mt-16 mx-auto max-w-xs sm:max-w-xl lg:max-w-4xl text-left bg-gray-100 rounded-lg text-gray-900`}
  .slick-track {
    ${tw`flex!`}
  }
  .slick-slide {
    ${tw`h-auto`}
  }
  .slick-slide > div {
    ${tw`h-full`}
  }
`;
const QuotesLeft = tw(QuotesLeftIcon)`w-8 h-8 lg:w-10 lg:h-10 text-gray-500 absolute top-0 left-0`;
const QuotesRight = tw(QuotesRightIcon)`w-8 h-8 lg:w-10 lg:h-10 text-gray-500 absolute bottom-0 right-0`;
const Testimonial = tw.div`px-6 py-12 sm:px-20 sm:py-16 focus:outline-none flex! flex-col justify-between h-full bg-gray-900`
const QuoteContainer = tw.div`relative`
const QuoteIcon = tw(QuoteIconBase)`absolute opacity-15 top-0 left-0 transform -translate-y-2 -translate-x-1/2 sm:-translate-x-full w-10 fill-current text-gray-500`
const Quote = tw.blockquote`font-medium sm:font-normal relative text-sm sm:text-xl text-center sm:text-left text-gray-100`
const CustomerInfoAndControlsContainer = tw.div`mt-8 flex items-center flex-col sm:flex-row justify-center text-center sm:text-left text-gray-100`
const CustomerImage = tw.img`w-16 h-16 rounded-full`
const CustomerNameAndProfileContainer = tw.div`mt-4 sm:mt-0 sm:ml-4 flex flex-col`
const CustomerName = tw.span`text-lg font-semibold`
const CustomerProfile = tw.span`text-sm font-normal text-gray-500`
const ControlsContainer = tw.div`sm:ml-auto flex`
const ControlButton = styled.button`
  ${tw`text-gray-600 hover:text-blue-700 focus:outline-none transition-colors duration-300 ml-4 first:ml-0 sm:first:ml-4 mt-4 sm:mt-0`}
  .icon {
    ${tw`fill-current w-6`}
  }
`;

export default ({
  subheading = "",
  heading = "Testimonials and Member Success",
  description = "",
  testimonials = [
    {
      customerName: "DeevesMcQueen",
      customerProfile: "E1 Member",
      imageSrc:
        "https://cdn.discordapp.com/avatars/696839547333640312/88b10ddac54d28213f7579916736555a.png?size=80",
      quote:
        "I got into trading back in July and I joined E1 in September because I knew I needed a better understanding of the markets to be successful. The amount of experience in this group is unbelievable. Munch, Jim, Blee, Richard Sweat and all of the other analysts have changed my game completely. Account has grown 100% since I joined and all of that is from the information provided by the analysts. If you are trying to learn GOOD trading strategies and discipline there is no better place then E1, bar none."
    },
    {
      customerName: "Neel",
      customerProfile: "E1 Member",
      imageSrc:
        "https://cdn.discordapp.com/avatars/559306656891273218/c4f7d398fca32345fe4533ef65ff6758.png?size=80",
      quote:
        "Kind of different testimony here:  Obviously the trading knowledge and expertise is far above and beyond any other group out there. But that’s not all this group has turned into. It is a genuine group of people who have become friends. The fact that strangers would donate to my dogs surgery shows the intimacy of this group and it’s admins. Thank you @Munchmallow and @jim.E. aka Darthpool for making such an incredible environment"
    },
    {
      customerName: "bstrade1234",
      customerProfile: "E1 Member -> Analyst",
      imageSrc:
        "https://cdn.discordapp.com/avatars/688663224362729483/2fc1920eaf19ba06629bd29b182218c6.png?size=80",
      quote:
        "If you really want to learn to become a successful trader, either full time or part-time.. there is only one Echelon 1, and only the best analysts/mentors to get you there. TY @Munchmallow @jim.E. aka Darthpool @Ra1"
    },
    {
      customerName: "Saint",
      customerProfile: "E1 Member",
      imageSrc:
        "https://cdn.discordapp.com/avatars/615637514039853281/af25a04b2aa7cef5355fd127b46a5b6d.png?size=80",
      quote:
        "After 9 weeks with Echelon, my compass is now calibrated. I don’t want to make this a long ass newsletter but will say this. I feel home with this community, the analysts and the members I have such an admiration and respect for all of you. Since day one, something weird happened, I felt warm, trusted and happy...Things I’ve learned the past 9 weeks; commitment, longevity, patience, and most importantly consistency. It is about building the empire, it’s going to be tough, I’m going to sacrifice, I’m going to feel the pain and fall, but luckily, I will fall on the Echelons. I am proud & happy to be part of this community."
    }
  ]
}) => {
  const [sliderRef, setSliderRef] = useState(null)

  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
        </HeadingContainer>
        <TestimonialsSlider arrows={false} ref={setSliderRef}>
          {testimonials.map((testimonial, index) => (
            <Testimonial key={index}>
              <QuoteContainer>
                {/* <QuotesLeft /> */}
                <Quote>
                  {testimonial.quote}
                </Quote>
                {/* <QuotesRight /> */}
              </QuoteContainer>
              <CustomerInfoAndControlsContainer>
                <CustomerImage src={testimonial.imageSrc} />
                <CustomerNameAndProfileContainer>
                  <CustomerName>
                    {testimonial.customerName}
                  </CustomerName>
                  <CustomerProfile>
                    {testimonial.customerProfile}
                  </CustomerProfile>
                </CustomerNameAndProfileContainer>
                <ControlsContainer>
                  <ControlButton onClick={sliderRef?.slickPrev}>
                    <ArrowLeftIcon className="icon" />
                  </ControlButton>
                  <ControlButton>
                    <ArrowRightIcon className="icon" onClick={sliderRef?.slickNext}/>
                  </ControlButton>
                </ControlsContainer>
              </CustomerInfoAndControlsContainer>
            </Testimonial>
          ))}
        </TestimonialsSlider>
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
