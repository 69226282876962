import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-black text-gray-100`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-gray-300`;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0`;
const Faq = tw.div`select-none cursor-pointer border-b-2 border-blue-500 hover:border-blue-500 transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-sm sm:text-lg font-semibold tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(motion.div)`hidden text-sm font-normal mt-4 text-gray-300`;

export default ({
  subheading = "",
  heading = "Frequently Asked Questions",
  description = "We often get questions on the service we provide. Here are resoponses to the most frequently asked questions.",
  faqs = [
    {
      question: "Is there a free trial?",
      answer:
        "Yes! We offer a 3 day free trial so that you can see if Echelon 1 is a good fit for you and your trading style. Check out the pricing section above to see what's included.\n\nTo ensure everyone is able to try a free 3-day trial, each individual is only entitled to ONE (1) free trial. If we find that you are taking advantage of free trials, we will bill you in accordance with the monthly subscription."
    },
    {
      question: "What's your trading style?",
      answer:
        "Between our traders, we cover day trades, swing trades, and LEAPs. We pride ourselves on being versatile and fluid; there are periods of time where day trading has better risk/reward and other times where swing trading has better risk/reward. We adapt our trading style to fit current market conditions."
    },
    {
      question: "What financial instruments do you trade?",
      answer:
        "We alert and provide trade ideas for US equities, options, and futures. Whatever you trade, we've got your back."
    },
    {
      question: "Do you provide mentorship?",
      answer:
        "Absolutely. We encourage all members to take advantage of this. No matter your trading style, our analysts can help you along your journey. In Echelon 1, we've helped many 7-figure traders as well as traders with small accounts looking to grow. Whatever your current situation, let's chat about it and chart a path forward."
    },
    {
      question: "What makes Echelon 1 different from other trading groups? ",
      answer:
        "We've been contributors and analysts in other groups before and identified a big problem: analysts push out alerts and that's it- no interaction and engaging with the community. We pride ourselves on not only pushing out bangers on the regular, but we're here to share the trading journey with you. Got questions on an alert? Ask away. Got questions on a potential trade idea you have? Hit us with it."
    },
    {
      question: "Can I get a refund?",
      answer:
        "Unfortunately, we do not offer refunds and all sales are final. We encourage people to leverage our free 7 day trial available to see if Echelon 1 might not be a good fit. Understanding that 7 days might not work to align with everyone's trading strategies and styles, we offer a month-to-month membership. During this time, we urge members to take full advantage of everything we offer and really grow with us. If it seems like it isn't a good fit during the month-to-month membership, we'll be sad to see you go, but just know that you can always reach out to us directly with any questions or comments.",
    }
  ]
}) => {
  const faqCol1 = [];
  const faqCol2 = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <Faq key={index} onClick={() => toggleQuestion(index)}>
        <Question>
          <QuestionText>{faq.question}</QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 }
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: { opacity: 1, height: "auto", marginTop: "16px", display: "block" },
            collapsed: { opacity: 0, height: 0, marginTop: "0px", display: "none" }
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {faq.answer}
        </Answer>
      </Faq>
    );

    if (index % 2 === 0) faqCol1.push(renderedFaq);
    else faqCol2.push(renderedFaq);

    return null;
  });
  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
        </HeadingContainer>
        <FaqsContainer>
          <FaqsColumn>{faqCol1}</FaqsColumn>
          <FaqsColumn>{faqCol2}</FaqsColumn>
        </FaqsContainer>
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
