import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import Tools from "components/testimonials/Tools.js";
import TradeLog from "components/blogs/GridWithFeaturedPost.js";
import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";
import Contact from "components/cta/GetStartedLight.js";
import Disclaimer from "components/faqs/SingleCol.js";
import Footer from "components/footers/FiveColumnDark.js";

document.body.style.backgroundColor = "black"

export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <div id="features">
        <Features />
        <Tools />
      </div>

      <div id="pricing">
        <Pricing />
      </div>
      <div id="testimonials">
        <Testimonial />
      </div>
      {/* <div id="trade-log">
        <TradeLog />
      </div> */}
      <div id="faq">
        <FAQ />
      </div>
      <div id="disclaimer">
        <Disclaimer />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <Footer />
    </AnimationRevealPage>
  );
}
